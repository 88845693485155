import { HTTP_METHOD } from '@readme/iso';
import { z } from 'zod';

export const SuggestedEndpoint = z.object({
  api: z.object({
    file: z.string().describe("The target endpoint's OAS filename."),
    method: z.nativeEnum(HTTP_METHOD),
    operationId: z.string().describe("The target endpoint's operationId."),
    url: z.string(),
  }),
  category: z.object({
    title: z.string(),
  }),
  title: z.string(),
});

export const Response = z.object({
  all: z.array(SuggestedEndpoint),
  recommended: z.array(SuggestedEndpoint),
});

export const UpdateApiConfigRequestBody = z
  .object({
    file: z.string().describe('OAS file location in filesystem.'),
    operationId: z.string().describe("The target endpoint's operationId."),
  })
  .required({ file: true, operationId: true });

export const UpdateApiConfigResponse = z.object({
  message: z.string(),
});

// this is business logic in what order we want to display api_config pages
// TODO: create consts export file in gitto to share values
export const CONFIG_ORDER = {
  'getting-started': 0,
  authentication: 1,
  'my-requests': 2,
};
